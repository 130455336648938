<template>
  <el-dialog
	  title="意见详情"
	  :visible.sync="dialogVisible"
	  :before-close="dialogBeforeClose">
	  <div v-html="dialogData.info"></div>
  </el-dialog>
</template>

<script>
export default {
	props: {
		feedbackHandleData: Object
	},
	data() {
		return {
			dialogVisible: false,
			dialogData: {},
		}
	},
	methods: {
		dialogBeforeClose() {
			this.dialogVisible = false;
		}
	},
	watch: {
		feedbackHandleData(data) {
			if (data) {
				this.dialogVisible = true;
				this.dialogData = data;
			}

		}
	}
}
</script>

<style lang="scss" scoped>
</style>
