<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>意见反馈</span>
		</div>

		<div class="course-wrapper-body">
			<div class="course-wrapper-function">
				<div class="function-left">
					<el-input placeholder="用户姓名" class="input-search" v-model="search.keyword"></el-input>
					<el-date-picker type="date" placeholder="选择日期" v-model="search.time"></el-date-picker>
					<el-button @click="buttonSearch" type="primary" icon="el-icon-search">查询</el-button>

					<el-button @click="buttonReset" icon="el-icon-refresh-left">重置</el-button>
				</div>
			</div>

			<el-table
				:data="feedbackData"
				class="feedback-table">
				<el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
				<el-table-column prop="courseName" label="课程活动" align="center"></el-table-column>
				<el-table-column prop="courseThemeName" label="主题/活动名称" align="center"></el-table-column>
				<el-table-column prop="catalogueName" label="目录标题" align="center"></el-table-column>
				<el-table-column prop="info" label="反馈内容" align="center" width="80">
					<template slot-scope="scope">
						<el-button @click="handleDetails(scope)" type="text">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="flag" label="角色" align="center" width="80">
					<template slot-scope="scope" >
						<el-tag v-if="scope.row.flag === '0'">家长</el-tag>
						<el-tag v-if="scope.row.flag === '1'">老师</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="用户姓名" align="center" width="100"></el-table-column>
				<el-table-column prop="createTime" label="反馈时间" width="140" align="center"></el-table-column>
			</el-table>
		</div>

		<div class="course-wrapper-footer">
			<div class="footer-right">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:page-size="pagination.size"
					:total="pagination.total"
					layout="prev, pager, next, sizes"
					background></el-pagination>
			</div>
		</div>

		<feedback-details :feedbackHandleData="feedbackHandleData"></feedback-details>
	</div>
</template>


<script>
import utils from '@/utils/tool';
import feedbackDetails from '@/views/feedback/feedbackDetails';
export default {
  name: 'feedback',
  components: { feedbackDetails },
  data() {
	  return {
			feedbackData: [], //反馈列表
		  feedbackHandleData: {},
			dicts: [], //课程字典列表
			search: { //搜索参数
				keyword: '',
				course: '',
				time: '',
			},
			pagination: { //页码
				current: 1,
				size: 10,
				total: 0
			}
	  }
  },
	created() {
		this.queryFeedback();
	},
  methods: {
		/**
		 * 查询反馈列表
		 */
		queryFeedback() {
			let data = {};
			let { current, size } = this.pagination;
			if (current) data.current = current;
			if (size) data.size = size;
			if (this.search.time) {
				data.startTime = this.search.time;
				data.startTime = utils.formatTime(data.startTime);
				data.endTime = data.startTime;
				data.startTime += 'T00:00:00';
				data.endTime += 'T23:59:59';
			}
			if (this.search.keyword) data.keyword = this.search.keyword;
			data.flag = 0;
			console.log(data);
			this.$api.getTeacherFeedback(data)
				.then(res => {
					if (res.data.code) {
						this.$message.error(res.data.msg);
						return;
					}
					let { data } = res.data;
					this.feedbackData = data.records;
					this.pagination.total = data.total;
					this.pagination.pages = data.pages;
				})
				.catch(e => {
					console.log(e);
				})
		},
	  handleDetails(data) {
			let { info } = data.row;
			this.feedbackHandleData = {
				info
			};
	  },
		feedbackHandleActive(activeName) {
			this.feedbackActiveName = activeName;
			this.queryFeedback();
		},
		handleSizeChange(val) {
			this.pagination.size = val;
			this.queryFeedback();
		},
		handleCurrentChange(val) {
			this.pagination.current = val;
			this.queryFeedback();
		},
		/**
		 * 按钮-查询
		 */
		buttonSearch() {
			this.queryFeedback();
		},
		/**
		 * 按钮-重置
		 */
		buttonReset() {
			this.search.keyword = '';
			this.search.course = '';
			this.search.time = '';
			this.buttonSearch();
		}
  }
}
</script>

<style lang="scss">
$colorBorder: #597ef7;
$danger: #f3676c;

.course-wrapper {
	background: #fff;
	.course-wrapper-title {
		display: flex;
		justify-content: space-between;
		padding: 20px 24px;
		font-size: 20px;
		font-weight: bold;
		border-bottom: 1px solid #d4d4d4;

		>span {
			position: relative;

			&:after {
				position: absolute;
				left: 50%;
				bottom: -20px;
				content: '';
				width: 25px;
				height: 4px;
				background: $colorBorder;
				transform: translateX(-50%);
			}
		}
	}

	.course-wrapper-body {
		padding: 20px;
		overflow-x: auto;
		.course-wrapper-function {
			padding-bottom: 20px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.function-left,
			.function-right {
				display: flex;
				margin-bottom: 10px;
			}

			.function-left {
				.el-input {
					margin-right: 10px;
				}
			}

			.el-input {
				width: 200px;
				&.input-select,
				&.input-search {
					width: 200px;
				}
			}
		}

		.feedback-table {
			// min-width: 1100px;

		}

		.table-handle {
			.el-button--text {
				&.button-danger {
					color: $danger;
				}
			}
			.el-button--text + span {
				padding: 0 2px;
				color: #ebeef5;
			}
		}
	}

	.course-wrapper-footer {
		padding: 0 20px 30px;
		.footer-right {
			text-align: right;
		}
	}
}

.textarea-inputted {
	.el-textarea__inner {
		height: 250px;
	}

	.el-input__count:before {
		content: '已输入';
	}
}
.feedback-handle {
	margin-bottom: 10px;
}
</style>
